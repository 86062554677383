import {useEffect, useState, useContext, Fragment} from "react";
import EstimatedInvoiceValueHistory from "./EstimatedInvoiceValueHistory";
import {AuthContext} from "../../../contexts/AuthContext";
import SmallSpinner from "../../SmallSpinner";

function EstimatedInvoiceValue() {
    const [estimatedInvoiceValues, setEstimatedInvoiceValues] = useState([]);
    const {token} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const json = await fetchEstimatedInvoiceValues();
                if (!json.error) {
                    setEstimatedInvoiceValues(json.estimatedInvoiceValues);
                }
            }
            catch (e) {
                console.error(e);
            }
            setLoading(false);
        })();
    }, []);

    async function fetchEstimatedInvoiceValues() {
        const response = await fetch(process.env.REACT_APP_endPoint + '/v1/dashboard/estimatedInvoiceValues', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        return response.json();
    }


    return (
        <tr>
            <td></td>
            <td>Estimated interco pricing on {estimatedInvoiceValues[estimatedInvoiceValues.length - 1]?.stamp.toLocaleString()}:</td>
            <td>{estimatedInvoiceValues.length > 0 ? <Fragment>&euro;{estimatedInvoiceValues[estimatedInvoiceValues.length - 1].estimatedInvoiceValue.toLocaleString()}</Fragment> : loading ? <SmallSpinner />: 'N.A.'} (<EstimatedInvoiceValueHistory estimatedInvoiceValues={estimatedInvoiceValues}/>)</td>
        </tr>
    );
}

export default EstimatedInvoiceValue;
