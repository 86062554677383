import {Fragment, useContext, useEffect, useRef, useState} from "react";
import Pagination from "../Pagination";
import Search from "../Search";
import {SortContext} from "../../contexts/SortContext";
import SortableHeader from "../SortableHeader";
import CustomerTaskFilters from "./CustomerTaskFilters";
import {AuthContext} from "../../contexts/AuthContext";
import Spinner from "../Spinner";
import Error from "../Alerts/Error";
import CustomerTaskRow from "./CustomerTaskRow";
import SmallSpinner from "../SmallSpinner";
import {addCurrencies} from "../../util/functions";

function CustomerTasks() {
	const [sortBy, setSortBy] = useState("name");
	const [asc, setAsc] = useState(false);
	const [desc, setDesc] = useState(false);
	const [search, setSearch] = useState("");
	const [invoiceStatus, setInvoiceStatus] = useState("");
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [customerTasks, setCustomerTasks] = useState([]);
	const [error, setError] = useState("");
	const [quantity, setQuantity] = useState(20);
	const [creationDateFrom, setCreationDateFrom] = useState('');
	const [creationDateTo, setCreationDateTo] = useState('');
	const [invoiceDateFrom, setInvoiceDateFrom] = useState('');
	const [invoiceDateTo, setInvoiceDateTo] = useState('');
	const [expectedDueDateFrom, setExpectedDueDateFrom] = useState('');
	const [expectedDueDateTo, setExpectedDueDateTo] = useState('');
	const [deliveryDateFrom, setDeliveryDateFrom] = useState('');
	const [deliveryDateTo, setDeliveryDateTo] = useState('');
	const [customer, setCustomer] = useState([]);
	const [status, setStatus] = useState([]);
	const [exportLoading, setExportLoading] = useState(false);
	const [totals, setTotals] = useState({euro: {}, usd: {}, pln: {}});
	const [totalsLoading, setTotalsLoading] = useState(false);

	const abortController = useRef();

	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setTotalsLoading(true);
			setError('');
			try {
				const jsonTotals = await fetchTotals();
				if (isMounted) {
					if (jsonTotals.error) {
						setError(jsonTotals.error);
					}
					else {
						setTotals({euro: jsonTotals.totalsEUR, usd: jsonTotals.totalsUSD, pln: jsonTotals.totalsPLN});
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setError('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setTotalsLoading(false);
				}
			}
		})();


		return () => {
			isMounted = false;
		}
	}, [invoiceStatus, creationDateFrom, creationDateTo, invoiceDateFrom, invoiceDateTo, expectedDueDateFrom, expectedDueDateTo, deliveryDateFrom, deliveryDateTo, customer.toString(),status.toString(), search]);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError('');
			try {
				const jsonMaxPage = await fetchMaxPage(search, invoiceStatus, sortBy, asc, desc);
				if (isMounted) {
					if (jsonMaxPage.error) {
						setError(jsonMaxPage.error);
					}
					else {
						setMaxPage(jsonMaxPage.maxPage);
						if (page > jsonMaxPage.maxPage) {
							setPage(jsonMaxPage.maxPage);
						}
					}
				}
				const jsonCustomerTasks = await fetchCustomerTasks();
				if (isMounted) {
					if (jsonCustomerTasks.error) {
						setError(jsonCustomerTasks.error);
					}
					else {
						setCustomerTasks(jsonCustomerTasks.customerTasks);
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setError('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		})();


		return () => {
			isMounted = false;
		}
	}, [page, quantity, invoiceStatus, creationDateFrom, creationDateTo, invoiceDateFrom, invoiceDateTo, expectedDueDateFrom, expectedDueDateTo, deliveryDateFrom, deliveryDateTo, customer.toString(),status.toString(), search, sortBy, asc, desc]);

	async function fetchTotals() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		if (creationDateFrom) {
			params.append('creationDateFromCustomerTask', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateToCustomerTask', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFromCustomer', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateToCustomer', invoiceDateTo);
		}
		if (expectedDueDateFrom) {
			params.append('expectedDueDateFrom', expectedDueDateFrom);
		}
		if (expectedDueDateTo) {
			params.append('expectedDueDateTo', expectedDueDateTo);
		}
		if (deliveryDateFrom) {
			params.append('deliveryDateFromCustomerTask', deliveryDateFrom);
		}
		if (deliveryDateTo) {
			params.append('deliveryDateToCustomerTask', deliveryDateTo);
		}
		params.append('customer', customer);
		params.append('status', status);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customerTasks/totals?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchCustomerTasks() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (expectedDueDateFrom) {
			params.append('expectedDueDateFrom', expectedDueDateFrom);
		}
		if (expectedDueDateTo) {
			params.append('expectedDueDateTo', expectedDueDateTo);
		}
		if (deliveryDateFrom) {
			params.append('deliveryDateFrom', deliveryDateFrom);
		}
		if (deliveryDateTo) {
			params.append('deliveryDateTo', deliveryDateTo);
		}
		params.append('customer', customer);
		params.append('status', status);

		params.append('page', page);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customerTasks?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchMaxPage() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (expectedDueDateFrom) {
			params.append('expectedDueDateFrom', expectedDueDateFrom);
		}
		if (expectedDueDateTo) {
			params.append('expectedDueDateTo', expectedDueDateTo);
		}
		if (deliveryDateFrom) {
			params.append('deliveryDateFrom', deliveryDateFrom);
		}
		if (deliveryDateTo) {
			params.append('deliveryDateTo', deliveryDateTo);
		}
		params.append('customer', customer);
		params.append('status', status);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customerTasks/maxPage?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function exportCustomerTasks() {
		try {
			setExportLoading(true);
			let params = new URLSearchParams();

			if (search) {
				params.append('search', search);
			}
			if (invoiceStatus) {
				params.append('invoiceStatus', invoiceStatus);
			}
			if (asc || desc) {
				params.append('asc', asc);
				params.append('desc', desc);
				params.append('sortBy', sortBy);
			}
			if (creationDateFrom) {
				params.append('creationDateFrom', creationDateFrom);
			}
			if (creationDateTo) {
				params.append('creationDateTo', creationDateTo);
			}
			if (invoiceDateFrom) {
				params.append('invoiceDateFrom', invoiceDateFrom);
			}
			if (invoiceDateTo) {
				params.append('invoiceDateTo', invoiceDateTo);
			}
			if (expectedDueDateFrom) {
				params.append('expectedDueDateFrom', expectedDueDateFrom);
			}
			if (expectedDueDateTo) {
				params.append('expectedDueDateTo', expectedDueDateTo);
			}
			if (deliveryDateFrom) {
				params.append('deliveryDateFrom', deliveryDateFrom);
			}
			if (deliveryDateTo) {
				params.append('deliveryDateTo', deliveryDateTo);
			}
			params.append('customer', customer);
			params.append('status', status);

			const controller = new AbortController();
			const signal = controller.signal;
			abortController.current = controller;

			const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customerTasks/exportCustomerTasks?' + params.toString(), {
				method: "GET",
				headers: {
					'Authorization': 'Bearer ' + token
				},
				signal
			});

			let data = [];
			const reader = response.body.getReader();
			reader.read().then(processData);

			function processData({done, value}) {
				try {
					if (done) {
						const uri = URL.createObjectURL(new Blob(data));
						let link = document.createElement("a");
						link.download = "customerTasksExport.tsv";
						link.href = uri;
						link.click();
						link.remove();
						setExportLoading(false);
					}
					else {
						data.push(value);
						return reader.read().then(processData);
					}
				}
				catch (e) {
					console.error(e);
				}
			}
		}
		catch (e) {
			console.error(e);
			setError('Failed to export these invoices.');
			setExportLoading(false);
		}
	}

	return (
		<SortContext.Provider value={{
			sortBy: sortBy,
			asc: asc,
			desc: desc,
			setSortBy: setSortBy,
			setAsc: setAsc,
			setDesc: setDesc
		}}>
			<div className={"m-2 border p-4 bg-white shadow-sm"}>
				{error && <Error message={error} /> }
				<Search placeHolder={"Search customer tasks on name, code, description, PO number, invoice number"} search={search} setSearch={setSearch}/>
				<div className={"mt-4"}>
					<CustomerTaskFilters invoiceStatus={invoiceStatus} setInvoiceStatus={setInvoiceStatus}
										 creationDateFrom={creationDateFrom}
										 setCreationDateFrom={setCreationDateFrom}
										 creationDateTo={creationDateTo}
										 setCreationDateTo={setCreationDateTo}
										 invoiceDateFrom={invoiceDateFrom}
					 					 setInvoiceDateFrom={setInvoiceDateFrom}
										 invoiceDateTo={invoiceDateTo}
					    				 setInvoiceDateTo={setInvoiceDateTo}
										 expectedDueDateFrom={expectedDueDateFrom}
										 setExpectedDueDateFrom={setExpectedDueDateFrom}
										 expectedDueDateTo={expectedDueDateTo}
										 setExpectedDueDateTo={setExpectedDueDateTo}
										 deliveryDateFrom={deliveryDateFrom}
										 setDeliveryDateFrom={setDeliveryDateFrom}
										 deliveryDateTo={deliveryDateTo}
										 setDeliveryDateTo={setDeliveryDateTo}
										 customer={customer} setCustomer={setCustomer}
					                     status={status} setStatus={setStatus}/>
				</div>
				<div className={"mt-4"}>
					<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
				</div>
				<button className={"btn btn-secondary mt-4"} onClick={exportCustomerTasks} disabled={exportLoading}>Export {exportLoading && <SmallSpinner />} customer tasks</button>
				<table className={"table table-striped m-0 mb-4 mt-0 border shadow-sm"}>
					<caption className={"caption-top"}>Customer tasks</caption>
					<thead className={"table-secondary"}>
					<tr>
						<SortableHeader columnName={"customer"} text={"Customer"}/>
						<SortableHeader columnName={"customerTasks.code"} text={"Code"}/>
						<SortableHeader columnName={"customerTasks.name"} text={"Name"}/>
						<SortableHeader columnName={"customerTasks.description"} text={"Description"}/>
						<th>Status</th>
						<th>External costs</th>
						{/*<th>Estimated bonus</th>*/}
						<SortableHeader columnName={"customerTasks.estimatedInvoiceValue"} text={"Estimated interco pricing"}/>
						<SortableHeader columnName={"customerTasks.expectedDueDate"} text={"Expected due date"}/>
						<SortableHeader columnName={"customerTasks.poNumber"} text={"PO number"}/>
						<SortableHeader columnName={"customerTasks.invoiceAmount"} text={"Invoice amount"}/>
						<SortableHeader columnName={"customerTasks.finalAmount"} text={"Final pricing"}/>
						<SortableHeader columnName={"customerTasks.invoiceDate"} text={"Invoice date"}/>
						<SortableHeader columnName={"customerTasks.invoiceNumber"} text={"Invoice number"}/>
						<SortableHeader columnName={"customerTasks.invoiced"} text={"Invoiced"}/>
					</tr>
					</thead>
					<tbody>
					{loading ? <tr><td colSpan={14}>
						<div className="d-flex justify-content-center">
							<Spinner />
						</div>
					</td></tr> :
							customerTasks.map(value => {
								return <CustomerTaskRow key={value.id} customerTask={value}/>;
							})
					}
					</tbody>
					<tbody>
					{totalsLoading ? <tr>
							<td colSpan={14}>
								<div className="d-flex justify-content-center">
									<Spinner/>
								</div>
							</td>
						</tr> :
						<>
							<tr>
								<th colSpan={5}>Total EUR:</th>
								<td><Fragment>&euro;{addCurrencies(totals.euro.externalCosts, totals.usd.externalCosts, totals.pln.externalCosts).toLocaleString()}</Fragment></td>
								{/*<td><Fragment>&euro;{addTotals(totals.euro.estimatedBonusValue, totals.usd.estimatedBonusValue).toLocaleString()}</Fragment></td>*/}
								<td>{totals.euro.estimatedInvoiceValue ?
									<Fragment>&euro;{totals.euro.estimatedInvoiceValue.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={2}/>
								<td>{totals.euro.invoiceAmount ?
									<Fragment>&euro;{totals.euro.invoiceAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td>{totals.euro.finalAmount ?
									<Fragment>&euro;{totals.euro.finalAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={3}/>
							</tr>
							<tr>
								<th colSpan={5}>Total USD:</th>
								<td>N.A.</td>
								{/*<td>N.A.</td>*/}
								<td>{totals.usd.estimatedInvoiceValue ?
									<Fragment>&#36;{totals.usd.estimatedInvoiceValue.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={2}/>
								<td>{totals.usd.invoiceAmount ?
									<Fragment>&#36;{totals.usd.invoiceAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td>{totals.usd.finalAmount ?
									<Fragment>&#36;{totals.usd.finalAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={3}/>
							</tr>
							<tr>
								<th colSpan={5}>Total PLN:</th>
								<td>N.A.</td>
								{/*<td>N.A.</td>*/}
								<td>{totals.pln.estimatedInvoiceValue ?
									<Fragment>P {totals.pln.estimatedInvoiceValue.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={2}/>
								<td>{totals.pln.invoiceAmount ?
									<Fragment>P {totals.pln.invoiceAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td>{totals.pln.finalAmount ?
									<Fragment>P {totals.pln.finalAmount.toLocaleString()}</Fragment> : 'N.A.'}</td>
								<td colSpan={3}/>
							</tr>
						</>
					}
					</tbody>
				</table>
				<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
			</div>
		</SortContext.Provider>
	);
}

export default CustomerTasks;
