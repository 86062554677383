import {AuthContext} from "../../../contexts/AuthContext";
import {useContext, useEffect, useRef, useState} from "react";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

function EstimatedInvoiceValueHistory({estimatedInvoiceValues}) {
    const myChart = useRef(null);
    const {token} = useContext(AuthContext);

    useEffect(() => {
        let chart = null;

        (async () => {
            try {
                const data = estimatedInvoiceValues.map(value => {
                    return {
                        x: value.stamp,
                        y: value.estimatedInvoiceValue
                    };
                });

                console.log(data);
                const config = {
                    type: 'line',
                    data: {
                        datasets: [{
                            label: "Estimated interco pricing",
                            data
                        }]
                    },
                    options: {
                        maintainAspectRatio: false,
                        scales: {
                            xAxis: {
                                type: 'time'
                            }
                        }
                    }
                };
                chart = new Chart(myChart.current, config);
            }
            catch (e) {
                console.error(e);
            }
        })();

        return () => {
            chart && chart.destroy();
        }
    }, [JSON.stringify(estimatedInvoiceValues)]);

    return (
        <>
            <button className={"btn btn-sm btn-primary"}  data-bs-toggle="modal" data-bs-target="#estimatedInvoiceValueModal">Show history</button>
            <div className="modal fade" id="estimatedInvoiceValueModal" tabIndex="-1" aria-labelledby="estimatedInvoiceValueModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="estimatedInvoiceValueModalTitle">Estimated interco pricing (history)</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div style={{height:"100%"}}>
                                <canvas ref={myChart}>

                                </canvas>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EstimatedInvoiceValueHistory;
