import {useContext, useEffect, useState} from "react";
import { purchaseOrdersContext } from "../../contexts/PurchaseOrdersContext";
import PurchaseOrderRow from "./PurchaseOrderRow";
import PurchaseOrderFilters from "./PurchaseOrderFilters";
import PurchaseOrdersExportConsumptionPerMonth from "./PurchaseOrdersExportConsumptionPerMonth";
import Spinner from "../Spinner";
import Error from "../Alerts/Error";

function PurchaseOrders() {
	const {purchaseOrders, filters, setFilters, getPurchaseOrders, billingEntities, loading, error} = useContext(purchaseOrdersContext);

	return (
        <div>
            <PurchaseOrdersExportConsumptionPerMonth />
            <PurchaseOrderFilters filters={filters} setFilters={setFilters} billingEntities={billingEntities} />
            <div className={"m-2 border p-4 bg-white shadow-sm"}>
                <div className={"table-responsive overflow-auto"}>
                    {error && <Error message={error} />}
                    <table className={"table table-striped table-bordered mt-2"} id={"resizeMe"}>
                        <thead className={"table-light"}>
                        <tr>
                            <th>Number</th>
                            <th>Description</th>
                            <th>Total value</th>
                            <th>Currency</th>
                            <th>Reference PDF</th>
                            <th>Value consumed</th>
                            <th>Value remaining</th>
                            <th>Customer</th>
                            <th>Comments</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading && <tr><td colSpan={6}><Spinner /></td></tr>}
                            {purchaseOrders.map(value => {
                                return <PurchaseOrderRow purchaseOrder={value} key={value.id} />;
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
	)
}

export default PurchaseOrders;
