import { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";

export const purchaseOrdersContext = createContext();

export const  PurchaseOrdersProvider = ({ children }) => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [filters, setFilters] = useState({});
    const [billingEntities, setBillingEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const {token} = useContext(AuthContext);


    function filterPurchaseOrders(contents) {
        let purchaseOrdersCopy = contents;
        if (filters.billingEntityId) {
            purchaseOrdersCopy = purchaseOrdersCopy.filter(element => {
                return element.billingEntityId === parseInt(filters.billingEntityId)
            });
        }
        if (filters.startIssueDate && filters.endIssueDate) {
            purchaseOrdersCopy = purchaseOrdersCopy.filter(element => {
                return element.issueDate >= filters.startIssueDate && element.issueDate <= filters.endIssueDate
            });
        }
        if (filters.consumed) {
            purchaseOrdersCopy = purchaseOrdersCopy.filter(element => {
                if (filters.consumed === "0")
                    return true;
                else if (filters.consumed === "1")
                    return element.valueRemaining > 0;
                return element.valueRemaining <= 0;
            })
        }
        return purchaseOrdersCopy;
    }

    async function getPurchaseOrders() {
        try {
            const response = await fetch(process.env.REACT_APP_endPoint + '/v1/purchaseOrders', {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.ok) {
                const { purchaseOrders: contents } = await response.json();
                setPurchaseOrders(filterPurchaseOrders(contents));
            }
            else {
                setError("Failed to get POs");
            }
        }
        catch (e) {
            setError("Failed to get POs");
            console.error(e);
        }
    }

    async function getBillingEntities() {
        try {
            const response = await fetch(process.env.REACT_APP_endPoint + '/v1/billingEntities', {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.ok) {
                const { billingEntities: contents } = await response.json();
                setBillingEntities(contents);
            }
            else {
                setError("Failed to get Billing Entities");
            }
        }
        catch (e) {
            setError("Failed to get Billing Entities");
            console.error(e);
        }
    }

    useEffect(() => {
        setLoading(true);
        getPurchaseOrders();
        getBillingEntities();
        setLoading(false);
    }, [filters]);

    return (
        <purchaseOrdersContext.Provider value={{purchaseOrders, filters, setFilters, getPurchaseOrders, billingEntities, loading, error}}>
            {children}
        </purchaseOrdersContext.Provider>
    );
}
