import {useContext, useState, useRef, useEffect} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { saveAs } from "file-saver";
import { purchaseOrdersContext } from "../../contexts/PurchaseOrdersContext";


function PurchaseOrdersExportConsumptionPerMonth() {
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customers, setCustomers] = useState([]);
    const { token } = useContext(AuthContext);
    const { purchaseOrders } = useContext(purchaseOrdersContext);
    const currentYear = parseInt(new Date().getFullYear());

    useEffect(() => {
        // create a deduped list of customers
        const customerIds = [...new Set(purchaseOrders.map(element => element.customer.id))];
        setCustomers(customerIds.map(id => purchaseOrders.find(purchaseOrder => purchaseOrder.customer.id === id ).customer));
    }, [purchaseOrders]);

    async function onSubmit(evt) {
        evt.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_endPoint}/v1/purchaseOrders/consumption/${year}/${month}/${customerId}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const blob = await response.blob();
                saveAs(blob, `POs_consumption_${year}_${month}.tsv`);
            }
            else {
                console.log(response);
            }
        }
        catch(e) {
            console.error(e);
        }
    }

	return (
		<div className={"m-2 border p-4 bg-white shadow-sm"}>
            <form onSubmit={onSubmit}>
                <div className="row mb-3">
                    <h3>PO consumption export per month</h3>
                </div>
                <div className="row">
                    <div className="col">
                        <select id="year" className={"form-select"} required={true} value={year} onChange={(evt) => {
                            setYear(evt.target.value);
                        }}>
                            <option value="">Select a year</option>
                            <option value={currentYear}>{currentYear}</option>
                            <option value={currentYear - 1}>{currentYear - 1}</option>
                            <option value={currentYear - 2}>{currentYear - 2}</option>
                            <option value={currentYear - 3}>{currentYear - 3}</option>
                            <option value={currentYear - 4}>{currentYear - 4}</option>
                        </select>
                    </div>
                    <div className="col">
                        <select id="month" className={"form-select"} required={true} value={month} onChange={(evt) => {
                            setMonth(evt.target.value);
                        }}>
                            <option value="">Select a month</option>
                            <option value={`01`}>january</option>
                            <option value={`02`}>february</option>
                            <option value={`03`}>march</option>
                            <option value={`04`}>april</option>
                            <option value={`05`}>may</option>
                            <option value={`06`}>june</option>
                            <option value={`07`}>july</option>
                            <option value={`08`}>august</option>
                            <option value={`09`}>september</option>
                            <option value={`10`}>october</option>
                            <option value={`11`}>november</option>
                            <option value={`12`}>december</option>
                        </select>
                    </div>
                    <div className="col">
                        <select id="customerId" className={"form-select"} required={true} value={customerId} onChange={(evt) => {
                            setCustomerId(evt.target.value);
                        }}>
                            <option value="">Select a customer</option>
                            {customers.map(element => {
                                return <option key={element.id} value={element.id}>{element.firstName} {element.lastName}</option>
                            })}
                        </select>
                    </div>
                    <div className="col">
                        <button type="submit" className={"btn btn-secondary"}>Export PO consumption per month</button>
                    </div>
                </div>
            </form>
        </div>
	);
}

export default PurchaseOrdersExportConsumptionPerMonth;
