import {NavLink} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import {useContext} from "react";

function Navbar() {
	const {setUser, setToken} = useContext(AuthContext);

	return (
		<div className={"navbar navbar-expand-lg navbar-light bg-warning"}>
			<div className={"container-fluid px-4"}>
				<a href="#" className={"navbar-brand"}>Datamundi Accounting Portal</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mx-auto mb-2 mb-lg-0">
						<li className={"nav-item dropdown"}>
							<a className={"nav-link dropdown-toggle" } href="#" id="navbarDropdownDashboard" role="button"
							   data-bs-toggle="dropdown" aria-expanded="false">
								Dashboard
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdownDashboard">
								<li>
									<NavLink className="dropdown-item" to="/dashboard/overview" activeClassName={"active"}>Overview</NavLink>
								</li>
								<li>
									<NavLink className="dropdown-item" to="/dashboard/indirectCosts" activeClassName={"active"}>Indirect costs</NavLink>
								</li>
							</ul>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/customerTasks" activeClassName={"active"}>Customer tasks</NavLink>
						</li>
						<li className="nav-item dropdown">
							<a className={"nav-link dropdown-toggle"} href="#" id="navbarDropdown" role="button"
							   data-bs-toggle="dropdown" aria-expanded="false">
								Payments
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<NavLink className="dropdown-item" to="/payments/unpaidInvoices" activeClassName={"active"}>Unpaid invoices</NavLink>
								</li>
								<li>
									<NavLink className="dropdown-item" to="/payments/paidInvoices" activeClassName={"active"}>Paid invoices</NavLink>
								</li>
							</ul>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/purchaseOrders" activeClassName={"active"}>POs</NavLink>
						</li>
					</ul>
					<ul className={"navbar-nav mb-2 mb-lg-0"}>
						<li className="nav-item dropdown">
							<a className={"nav-link dropdown-toggle"} href="#" id="navbarProfileDropdown" role="button"
							   data-bs-toggle="dropdown" aria-expanded="false">
								My profile
							</a>
							<ul className="dropdown-menu" aria-labelledby="navbarProfileDropdown">
								<li>
									<NavLink className="dropdown-item" to="/profile/settings" activeClassName={"active"}>Settings</NavLink>
								</li>
								<li>
									<a className="dropdown-item" href={"#"} onClick={(evt) => {
										localStorage.removeItem('token');
										setUser({});
										setToken(null);
									}}>Sign out</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
