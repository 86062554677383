import { useContext } from "react";
import { purchaseOrdersContext } from "../../contexts/PurchaseOrdersContext";

function PurchaseOrderFilters() {
    const { filters, setFilters, billingEntities } = useContext(purchaseOrdersContext);

	return (
		<div className={"m-2 border p-4 bg-white shadow-sm"}>
            <h3>Filters</h3>
			<form>
                <div className="row">
                    <div className="col">
                        <label htmlFor="billingEntityId" className={"form-label"}>Billing entity:</label><br/>
                        <select id="billingEntityId" className={"form-select"} value={filters.billingEntityId} onChange={(evt) => {
                            setFilters(prev => { return {...prev, billingEntityId: evt.target.value} })
                        }}>
                            <option value="">Select an option</option>
                            {billingEntities.map(value => {
                                return <option key={value.id} value={value.id}>{value.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="col">
                        <label htmlFor="consumed" className={"form-label"}>State:</label><br/>
                        <select id="consumed" className={"form-select"} value={filters.consumed} onChange={(evt) => {
                            setFilters(prev => { return {...prev, consumed: evt.target.value} })
                        }}>
                            <option value="0">All</option>
                            <option value="1">Available</option>
                            <option value="2">Consumed</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label for="startIssueDate">Start Issue Date</label>
                        <input type="date" className="form-control" id="startIssueDate" name="startIssueDate" onChange={(evt) => {
                            setFilters(prev => { return {...prev, startIssueDate: evt.target.value }});
                        }} />
                    </div>
                    <div className="col">
                        <label for="endIssueDate">End Issue Date</label>
                        <input type="date" className="form-control" id="endIssueDate" name="endIssueDate" onChange={(evt) => {
                            setFilters(prev => { return {...prev, endIssueDate: evt.target.value }});
                        }} />
                    </div>
                </div>
			</form>
		</div>
	);
}

export default PurchaseOrderFilters;
